import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { SanIconComponent } from '@san/tools/ui/icon';
import { MenuService } from '../../services/menu.service';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    SanIconComponent,
    RouterLink,
    TranslatePipe,
  ],
})
export class MenuBarComponent {
  private readonly navigationService = inject(NavigationService);
  private readonly userService = inject(UserService);
  private readonly menuService = inject(MenuService);

  get menu() {
    let menus = this.menuService.menu();
    if (this.userService.proIsConnected()) {
      menus = this.menuService.proMenu();
    } else if (this.userService.patientIsConnected()) {
      menus = this.menuService.patientMenu();
    }
    return menus;
  }

  get visibleMenus() {
    return this.menu?.filter((m) => m.visible !== false);
  }

  isActivePage(url: string): boolean {
    return url === this.navigationService.currentRouter.url;
  }
}
