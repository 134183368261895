import { Injectable, inject } from "@angular/core";
import { IconName, MenuItem } from "@san/tools/models";
import { BaseMenu, PatientMenu, ProMenu } from "../models/enum/user-menu.enum";
import { AccountType } from "../models/enum/user-shared.enum";
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class MenuService {

  private readonly userService = inject(UserService);

  menu(): MenuItem[] {
    return [
      {
        id: 1,
        label: 'menu.accueil',
        link: `${BaseMenu.HOME}`,
        visible: true,
        icon: IconName.HOME
      },
      {
        id: 2,
        label: 'menu.medecins',
        link: `/${BaseMenu.HOME}/${BaseMenu.DOCTOR}`,
        visible: true,
        icon: IconName.DOCTOR
      },
      {
        id: 3,
        label: 'menu.espace-patient',
        link: `/account/${AccountType.PATIENT}/login`,
        visible: true,
        icon: IconName.USER
      }
    ];
  }

  proMenu(): MenuItem[] {
    const base = `/${BaseMenu.PRO}`;
    const menu = [{ id: ProMenu.PROFILE, label: 'menu.profile', link: `${base}/${ProMenu.PROFILE}`, icon: IconName.USER_TIE }];

    if (this.userService.medecinIsConnected() || this.userService.isEntityManager()) {
      menu.unshift(...[
        { id: ProMenu.SYNTHESE, label: 'menu.synthese', link: `${base}/${ProMenu.SYNTHESE}`, icon: IconName.DISPLAY },
        { id: ProMenu.CALENDRIER, label: 'menu.calendrier', link: `${base}/${ProMenu.CALENDRIER}`, icon: IconName.CALENDAR },
        { id: ProMenu.RDV, label: 'menu.rdv', link: `${base}/${ProMenu.RDV}`, icon: IconName.TIME },
        { id: ProMenu.PATIENTS, label: 'menu.patients', link: `${base}/${ProMenu.PATIENTS}`, icon: IconName.USER_GROUP },
        { id: ProMenu.PAYEMENTS, label: 'menu.paiements', link: `${base}/${ProMenu.PAYEMENTS}`, icon: IconName.FILE_INVOICE },
        { id: ProMenu.STATS, label: 'menu.stats', link: `${base}/${ProMenu.STATS}`, icon: IconName.CHART_PIE },
      ]);
    }

    if (this.userService.medecinIsConnected()) {
      menu.push({ id: ProMenu.MEDECIN, label: 'menu.medecin', link: `${base}/${ProMenu.MEDECIN}`, icon: IconName.DOCTOR });
    }

    if (this.userService.isUserManager()) {
      menu.push(...[
        { id: ProMenu.USERS, label: 'menu.etablissement.users', link: `${base}/${ProMenu.USERS}`, icon: IconName.USER_GROUP }
      ]);
    }
    if (this.userService.isEntityManager()) {
      menu.push({ id: ProMenu.ETABLISSEMENT, label: 'menu.etablissement.label', link: `${base}/${ProMenu.ETABLISSEMENT}`, icon: IconName.HOSPITAL });
      menu.push({ id: ProMenu.LOCALISATION, label: 'menu.localisation', link: `${base}/${ProMenu.LOCALISATION}`, icon: IconName.LOCATION });
    }

    return menu;
  }

  patientMenu(): MenuItem[] {
    const base = `/${BaseMenu.PATIENT}`;
    return [
      { id: PatientMenu.SYNTHESE, label: 'menu.synthese', link: `${base}/${PatientMenu.SYNTHESE}`, icon: IconName.DISPLAY },
      { id: PatientMenu.RDV, label: 'menu.rdv', link: `${base}/${PatientMenu.RDV}`, icon: IconName.CALENDAR },
      { id: PatientMenu.PAYEMENTS, label: 'menu.paiements', link: `${base}/${PatientMenu.PAYEMENTS}`, icon: IconName.FILE_INVOICE },
      { id: PatientMenu.PROFILE, label: 'menu.profile', link: `${base}/${PatientMenu.PROFILE}`, icon: IconName.USER_TIE }
    ];
  }

}
